// Add your JS customizations here
import lozad from 'lozad';
import Swiper, {Pagination, Navigation, EffectFade} from 'swiper';
import {LuminousGallery} from 'luminous-lightbox';

(function ($) {
	let matchMediaXlUp = window.matchMedia('(min-width: 1200px)');
	let matchMediaXlDown = window.matchMedia('(max-width: 1199px)');
	let matchMediaLgDown = window.matchMedia('(max-width: 992px)');
	let matchMediaMdDown = window.matchMedia('(max-width: 767px)');

	// Youtube params
	const youtubeIframeApiUrl = '//www.youtube.com/iframe_api';
	let isYouTubeApiLoaded = false;

	/**
	 *  Lazy load
	 */
	if (document.querySelectorAll('.lazy').length > 0) {
		const elements = document.querySelectorAll('.lazy');
		const observer = lozad(elements);
		observer.observe();
	}

	const homeHeroSlides = document.querySelectorAll('body.home .hero-slide');
	if (homeHeroSlides.length > 1 && matchMediaLgDown.matches) {
		const handleClick = (e) => {
			homeHeroSlides.forEach(slide => {
				slide.classList.remove('active');
			});
			e.currentTarget.classList.add('active');
		}

		homeHeroSlides.forEach(slide => {
			slide.addEventListener('click', handleClick);

			let btnClose = slide.querySelector('.btn-close');
			btnClose.addEventListener('click', (e) => {
				e.preventDefault();
				e.stopImmediatePropagation();
				slide.classList.remove("active");
			});
		});
	}

	/**
	 *  Header button search
	 */
	const btnSearchToggle = document.querySelector('.btn-icon--search');
	if (btnSearchToggle) {
		btnSearchToggle.addEventListener('click', () => {
			document.querySelector('input#search').focus();
		});
	}

	const youtubeVideosHandler = (videos) => {
		videos.forEach(video => {
			const videoSlidePlay = video.querySelector('.video-slide__play');
			const youtubeVideoID = video.querySelector('.video-container').dataset.id;
			const videoIframe = video.querySelector('.video-iframe');
			let player;

			videoSlidePlay.addEventListener('click', function (e) {
				e.preventDefault();

				if (!isYouTubeApiLoaded) {
					let tag = document.createElement('script');
					tag.src = youtubeIframeApiUrl;
					let firstScriptTag = document.getElementsByTagName('script')[0];
					firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
				}

				setTimeout(function () {
					video.classList.add('video-played');
					onYouTubeIframeAPIReady();
				}, 600);

				isYouTubeApiLoaded = true;
			});

			function onYouTubeIframeAPIReady() {
				const bsToggleSelector = videoSlidePlay.dataset.bsToggle;
				const bsTargetSelector = videoSlidePlay.dataset.bsTarget;
				const bsTarget = document.querySelector(bsTargetSelector);
				const modalContent = bsTarget ? bsTarget.querySelector('.modal-body') : null;
				const videoIframeSelector = bsToggleSelector && bsToggleSelector === 'modal' && modalContent ? modalContent : videoIframe;

				/**
				 * Create iframe
				 * @type {YT.Player}
				 */
				player = new YT.Player(videoIframeSelector, {
					videoId: youtubeVideoID,
					playsinline: true,
					rel: true,
					events: {
						'onReady': onPlayerReady,
						'onStateChange': onPlayerStateChange
					}
				});

				/**
				 *  Set modal title
				 */
				const postVideo = videoSlidePlay.closest('.post-video');
				const postVideoTitle = postVideo.querySelector('.post-video__title');
				const modalVideoTitle = bsTarget.querySelector('.modal-title');
				if ( postVideoTitle ) {
					modalVideoTitle.textContent = postVideoTitle.textContent;
				}

				// Destroy player when modal closed
				if ( bsTarget ) {
					bsTarget.addEventListener('hidden.bs.modal', function (event) {
						player.destroy();
					})
				}
			}

			function onPlayerReady(event) {
				event.target.playVideo();
			}

			let done = false;
			function onPlayerStateChange(event) {
				if (event.data == YT.PlayerState.PLAYING && !done) {
					// setTimeout(stopVideo, 6000);
					done = true;
				}
			}

			function stopVideo() {
				player.stopVideo();
			}
		});
	}

	/**
	 *  Sliders & Carousels (swiper)
	 */
	if (document.querySelector('.video-slider')) {
		const video_slider = new Swiper('.video-slider', {
			modules: [Pagination],
			slidesPerView: 1,
			speed: 1500,
			on: {
				init: function () {
					const videoSlides = document.querySelectorAll('.video-slide');

					if (videoSlides.length) {
						youtubeVideosHandler( videoSlides );
					}
				},
			},
			pagination: {
				el: ".video-slider__pagination",
				clickable: true,
			},
		});
	}

	const videoGalleryFeatureVideos = document.querySelectorAll('.feature-video');
	if ( videoGalleryFeatureVideos.length ) {
		youtubeVideosHandler( videoGalleryFeatureVideos );
	}

	const postsVideos = document.querySelectorAll('.post-video');
	if ( postsVideos.length ) {
		youtubeVideosHandler( postsVideos );
	}

	const videoCarousels = document.querySelectorAll('.videos-carousel');
	if (videoCarousels.length) {
		videoCarousels.forEach( carousel => {
			new Swiper(carousel, {
				modules: [Navigation],
				spaceBetween: 12,
				navigation: {
					prevEl: carousel.closest('.videos-carousel-wrapper').querySelector('.arrow--left'),
					nextEl: carousel.closest('.videos-carousel-wrapper').querySelector('.arrow--right'),
				},
				breakpoints: {
					// when window width is >= 320px
					320: {
						slidesPerView: 1.2,
					},
					// when window width is >= 576px
					576: {
						slidesPerView: 3,
					},
					// when window width is >= 768px
					768: {
						slidesPerView: 3,
					},
					992: {
						slidesPerView: 4,
					},
				},
			});
		});
	}

	if (document.querySelector('.swiper.featured-products')) {
		const featuredProducts = new Swiper('.swiper.featured-products', {
			modules: [Navigation],
			spaceBetween: 12,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				// when window width is >= 320px
				320: {
					slidesPerView: 1.2,
				},
				// when window width is >= 480px
				576: {
					slidesPerView: 2,
				},
				// when window width is >= 768px
				768: {
					slidesPerView: 3,
				},
			},
		});
	}

	/**
	 *  Scroll handling - begin
	 */
	let lastScrollTop = 0;
	const isScrollingDown = () => {
		let goingDown = false;
		let st = window.scrollY || document.documentElement.scrollTop;
		if (st > lastScrollTop) {
			goingDown = true;
		} else {
			goingDown = false;
		}
		lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

		return goingDown;
	};

	/**
	 *  Scroll handling - begin
	 */
	const headerNavbar = document.querySelector('header#wrapper-navbar .navbar');
	const handleScroll = () => {
		if (isScrollingDown()) {
			document.body.classList.remove("scroll-up");
			document.body.classList.add("scroll-down");
		} else {
			document.body.classList.add("scroll-up");
			document.body.classList.remove("scroll-down");
		}

		if (window.scrollY < 100) {
			document.body.classList.remove("scroll-up");
		}
	};

	window.addEventListener("scroll", handleScroll);
	// Scroll handling - end

	/**
	 *  Products archive filter
	 */
	const filterButtonReset = document.querySelector('.bapf_reset');
	const bapfSfilters = document.querySelectorAll('.bapf_sfilter:not(.bapf_rst_nofltr)');
	const berocketAjaxGroupFilterTitle = document.querySelector('.berocket_ajax_group_filter_title');
	const productFilterResetToggle = document.querySelector('#products-filters-reset');

	if (filterButtonReset && productFilterResetToggle) {
		productFilterResetToggle.addEventListener('click', (e) => {
			e.preventDefault();

			// TODO : to replace by vanilla js
			jQuery('.bapf_reset').trigger('click');
		});
	}

	if (berocketAjaxGroupFilterTitle && bapfSfilters.length === 0) {
		berocketAjaxGroupFilterTitle.style.display = 'none';
	}

	if (productFilterResetToggle && bapfSfilters.length === 0) {
		productFilterResetToggle.style.display = 'none';
	}

	const productFeatures = document.querySelector('.product__features');
	const productFeaturesList = document.querySelector('.product__features-list');
	if (productFeatures && productFeaturesList) {
		if (productFeaturesList.childNodes.length === 0) {
			productFeatures.classList.add('d-none');
		}
	}

	const productsFiltersToggle = document.querySelector('#products-filters-toggle');
	const productsFiltersCloseButtons = document.querySelectorAll('.products-filters-close');
	if (productsFiltersToggle && productsFiltersCloseButtons && matchMediaMdDown.matches) {
		productsFiltersToggle.addEventListener('click', function () {
			document.body.classList.toggle('products-filters-opened');
			document.body.style.overflow = 'hidden';
		});

		if (productsFiltersCloseButtons.length) {
			productsFiltersCloseButtons.forEach(closeButton => {
				closeButton.addEventListener('click', function (e) {
					e.preventDefault();
					document.body.classList.remove('products-filters-opened');
					document.body.style.removeProperty('overflow');
				});
			})
		}
	}

	/**
	 *  Product gallery lightbox
	 */
	const productGalleryLinks = document.querySelectorAll('.woocommerce-product-gallery__image a');
	if (productGalleryLinks.length) {
		new LuminousGallery(productGalleryLinks);
	}

	if (productGalleryLinks.length === 1) {
		productGalleryLinks[0].addEventListener('click', () => {
			document.querySelector('.lum-previous-button').style.display = 'none';
			document.querySelector('.lum-next-button').style.display = 'none';
		})
	}
})(jQuery);
